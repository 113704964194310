import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Sphere } from "@react-three/drei";
import { Color } from "three";
import styles from './App.module.css';

const App = () => {
  const MIN_RADIUS = 7.5;
  const MAX_RADIUS = 15;
  const DEPTH = 2;
  const NUM_POINTS = 2500;
  const currColor = useRef({
    r: 195,
    g: 150,
    b: 150
  })
  const audioRef = useRef(null);
  
  const toHex = (n) => {
    n = (n>255) ? 255 :((n<0) ? 0 : n);
    var hex = n.toString(16);
    while (hex.length < 2) {hex = "0" + hex; }
    return hex;
  }

  const getGradientStop = (ratio, startColor, endColor) => {
    ratio = ratio > 1 ? 1 : ratio < 0 ? 0 : ratio;
    startColor = `${toHex(currColor.current.r)}${toHex(currColor.current.g)}${toHex(currColor.current.b)}`;
    endColor = `${toHex(currColor.current.r + 40)}${toHex(currColor.current.g - 10)}${toHex(currColor.current.b + 5)}`;

    const c0 = startColor.match(/.{1,2}/g).map(
      (oct) => parseInt(oct, 16) * (1 - ratio)
    );
    const c1 = endColor.match(/.{1,2}/g).map(
      (oct) => parseInt(oct, 16) * ratio
    );
    const ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
    const color = ci
      .reduce((a, v) => (a << 8) + v, 0)
      .toString(16)
      .padStart(6, "0");

    return `#${color}`;
  };

  const calculateColor = (x, startColor, endColor) => {
    const maxDiff = MAX_RADIUS * 2;
    const distance = x + MAX_RADIUS;

    const ratio = distance / maxDiff;

    const stop = getGradientStop(ratio, startColor, endColor);
    return stop;
  };

  const randomFromInterval = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const pointsInner = Array.from(
    { length: NUM_POINTS },
    (v, k) => k + 1
  ).map((num) => {
    const randomRadius = randomFromInterval(MIN_RADIUS, MAX_RADIUS);
    const randomAngle = Math.random() * Math.PI * 2;

    const x = Math.cos(randomAngle) * randomRadius;
    const y = Math.sin(randomAngle) * randomRadius;
    const z = randomFromInterval(-DEPTH, DEPTH);

    const color = calculateColor(x);

    return {
      idx: num,
      position: [x, y, z],
      color,
    };
  });

  const pointsOuter = Array.from(
    { length: NUM_POINTS / 4 },
    (v, k) => k + 1,
  ).map((num) => {
    const randomRadius = randomFromInterval(MIN_RADIUS / 2, MAX_RADIUS * 2);
    const angle = Math.random() * Math.PI * 2;

    const x = Math.cos(angle) * randomRadius;
    const y = Math.sin(angle) * randomRadius;
    const z = randomFromInterval(-DEPTH * 10, DEPTH * 10);

    const color = calculateColor(x);

    return {
      idx: num,
      position: [x, y, z],
      color,
    };
  });

  const PointCircle = () => {
    const ref = useRef(null);
  
    useFrame(({ clock }) => {
      if (ref.current?.rotation) {
        ref.current.rotation.z = clock.getElapsedTime() * 0.05;
      }
    });
  
    return (
      <group ref={ref}>
        {pointsInner.map((point) => (
          <Point key={point.idx} position={point.position} color={point.color} />
        ))}
        {pointsOuter.map((point) => (
          <Point key={point.idx} position={point.position} color={point.color} />
        ))}
      </group>
    );
  };
  const Point = ({ position, color }) => {
    const meshData = useRef(null);

    useFrame(({ clock }) => {
      if(meshData.current?.color) {
        let startColor = `${toHex(currColor.current.r)}${toHex(currColor.current.g)}${toHex(currColor.current.b)}`;
        let endColor = `${toHex(currColor.current.r + 40)}${toHex(currColor.current.g - 10)}${toHex(currColor.current.b + 5)}`;
        

        let newColor = new Color(calculateColor(position[0], startColor, endColor));
        meshData.current.color = newColor;
        meshData.current.emissive = newColor;
      }
    })
    return (
      <Sphere position={position} args={[0.1, 10, 10]}>
        <meshStandardMaterial
          emissive={color}
          emissiveIntensity={0.5}
          roughness={0.5}
          color={color}
          ref={meshData}
        />
      </Sphere>
    );
  };

  const isMouseDown = useRef(false);

  const setMouseDown = (mouseDown) => {
    if(!mouseDown && audioRef.current && audioRef.current.paused) {
      audioRef.current.play();
    } 
    isMouseDown.current = mouseDown;
  }

  const updateColors = (e) => {
    if(isMouseDown.current) {
      const sizes = {};
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      let x = e.pageX;
      let y = e.pageY;
      if(e.type === 'touchmove' && e.touches && e.touches.length > 0) {
        x = e.touches[0].pageX;
        y = e.touches[0].pageY;
      }
      currColor.current = {
        r: Math.round((x / sizes.width) * 255),
        g: Math.round((y / sizes.height) * 255),
        b: 150
      }
    }
  }

  return (
    <div 
      className={styles.relative}
      onMouseDown={()=>setMouseDown(true)}
      onMouseMove={updateColors}
      onMouseUp={()=>setMouseDown(false)} 
      onTouchStart={()=>setMouseDown(true)}
      onTouchMove={updateColors}
      onTouchEnd={()=>setMouseDown(false)} 
    >
      <Canvas
        camera={{
          position: [10, -7.5, -5],
        }}
        style={{ height: "100vh" }}
        className={styles.canvas}
      >
        <OrbitControls maxDistance={20} minDistance={10} />
        <directionalLight />
        <pointLight position={[-30, 0, -30]} power={10.0} />
        <PointCircle />
      </Canvas>

      <h1 className={styles.title}>
        Himani Singh
      </h1>
      <audio ref={audioRef} src="/need.mp3" className={styles.audio} hidden loop/>
    </div>
  );
};

export default App;